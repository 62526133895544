// Dependent colors
$black: #000000;

$classic-base-color: #19263a;
$classic-primary-color: #9baec8;
$classic-secondary-color: #d9e1e8;
$classic-highlight-color: #20a2fb;

$ui-base-color: $classic-base-color !default;
$ui-primary-color: $classic-primary-color !default;
$ui-secondary-color: $classic-secondary-color !default;
$ui-highlight-color: $classic-highlight-color !default;

$darker-text-color: lighten($ui-primary-color, 20%) !default;
$dark-text-color: lighten($ui-primary-color, 12%) !default;
$secondary-text-color: lighten($ui-secondary-color, 6%) !default;
$highlight-text-color: lighten($ui-highlight-color, 10%) !default;
$action-button-color: lighten($ui-base-color, 50%);

$inverted-text-color: $black !default;
$lighter-text-color: darken($ui-base-color, 6%) !default;
$light-text-color: darken($ui-primary-color, 40%) !default;

$blurple-500: #2076d9;

$ui-button-tertiary-color: $blurple-500 !default;
$ui-button-tertiary-border-color: $blurple-500 !default;
